<template>
    <div id="redpackage" class="content">
        <span id="tip">获取抽奖资格，本活动由"深信计协"赞助</span>
        <p>如何获得抽奖机会？</p>
        <ul>
            <li>1.转发本页面到朋友圈（每天一次机会,抽奖次数次日增加）</li>
            <li>2.查询课表并打开微信上课提醒</li>
        </ul>
        <br>
        <p>中奖后如何领取奖品？</p>
        <p>红包封面将会在下次微信推文中送出。</p>
        <br>
        <p>奖品情况:</p>
        <p style="font-size: 20px">{{msg}}</p>
        <br>
        <md-button  type="primary" @click="choujiang">开始抽奖</md-button>

    </div>
</template>

<script>
    import { Toast } from 'mand-mobile'
    export default {
        name: "Redpackage",
        data(){
            return {
                msg :'奖品情况'
            }
        },
        beforeMount() {
            this.$API.services.choujiangInit().then(res=>{
                this.msg = `中奖人数${res.data.zhongjiang}，奖品总数${res.data.total}`
            })
        },
        methods:{
            choujiang(){
                this.$API.services.choujiang().then(res=>{
                    if (res.errno == 0){
                        Toast.succeed(res.data.res)
                    }else{
                        Toast.failed(res.msg)
                    }
                })
            }
        }
    }
</script>

<style scoped>
    #redpackage {
        font-size: 25px;

    }

    #tip {
        font-size: 45px;
    }
</style>
