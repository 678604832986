import axios from '../util/request'

export default {
    getUserInfo() {
        return axios.get('/user/user/userinfo')
    },
    hasUser(){
        return axios.get('/score/Services/hasUser')
    }

}
