<template>
  <div class="content">
    <Title title="考试时间查询"></Title>
    <StuInput v-show="!isLogin" @stuinput="login" :loading="loading" :inactive="loading"></StuInput>
    <WechatDialog msg="请先关注公众号" :banner="banner" ref="wechatd"></WechatDialog>
    <md-field v-show="isLogin">
      <md-cell-item v-for="(item,index) in examList" :key="index" :title="item.kcmc" :brief="`时间：${item.kssj}`"
                    :addon="item.cdmc"/>
    </md-field>
  </div>
</template>

<script>
import {Toast} from "mand-mobile";

export default {
  name: "Exam",
  data() {
    return {
      banner: '',
      isLogin: false,
      loading: false,
      examList: []
    }
  },
  methods: {


    login(e) {
      // 票据
      this.loading = true
      this.$API.user.hasUser().then(res => {
        if (res.errno == 474) {
          //  弹出框
          this.banner = '/wechat/fuwuhao8cm.png'
          this.$refs.wechatd.openIt()
          this.loading = false
        } else {
          // 查询
          this.$API.score.getScore2(e.stuno, e.stupwd, this.errorCount, Math.random() * 100000, Math.random() * 100000, e.passwordType, 'exam').then(res => {
            this.loading = false
            if (res.errno == 0) {
              console.log(res)
              this.examList = res.data.items
              this.isLogin = true
            } else {

              Toast.failed(res.msg)
            }
          }).catch(e => {
            console.log(e);
            this.loading = false
            Toast.failed('服务器临时维护,请重试多几次')
          })
        }
      })


    },
  }
}
</script>

<style scoped>

</style>
