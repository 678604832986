<template>
  <div>
    <md-toast
      ref="toast"
      :icon="toastCfg.icon"
      icon-svg
      :content="toastCfg.content"
      square
      hasMask
    >
    </md-toast>
    <md-popup
      v-model="isShowVerifyContent"
      style="width: 100%"
      :mask-closable="false"
      position="center"
    >
      <VerifyContent
        @verify="submitVerify"
        v-show="isShowVerifyContent"
      ></VerifyContent>
    </md-popup>
  </div>
</template>

<script>
import VerifyContent from "@/components/verificationCode/verifyContent";
import { ActivityIndicator, Button, Toast } from "mand-mobile";

export default {
  name: "index",
  components: {
    [Toast.component.name]: Toast.component,
    [Button.name]: Button,
    [ActivityIndicator.name]: ActivityIndicator,
    VerifyContent,
  },

  data() {
    return {
      isShowLoading: false,
      isShowVerifyContent: false,
      toastCfg: {
        icon: '',
        content: ''
      }
    };
  },
  methods: {
    submitVerify(verifyInfo) {
      this.showLoadingToast();
      this.$emit('verify', verifyInfo)
      /* this.$API.xtgl.submitZfcaptchaLogin(verifyInfo).then(res => {
        if (res.errno === 0) {
          this.$refs.toast.hide();
        this.isShowVerifyContent = false
        }
      }).catch(err => {
        this.$refs.toast.hide();
        this.isShowVerifyContent = false
      }) */
    },
    showLoadingToast(toastCfg = {icon: 'ring', content: '请稍后...'}) {
      this.toastCfg = toastCfg
      if (this.isShowLoading) {
        this.$refs.toast.hide();
        this.isShowLoading = false;
      } else {
        this.$refs.toast.show();
        this.isShowLoading = true;
      }
    },
  },
};
</script>

<style scoped>
</style>
