<template>
  <div id="app">
    <md-notice-bar
        mode="closable"
        icon="volumn"
        scrollable
        multi-rows
    >
      业余时间开发，部分功能可能存在问题。如需咨询请加师兄微信：690712575
    </md-notice-bar>
    <router-view/>
    <div style="height: 120px"></div>
  </div>
</template>
<script>
import {NoticeBar} from 'mand-mobile'

import {getToken, setToken} from "./util/auth";
import {isWeiXin} from "@/util/utils";

export default {
  components: {
    [NoticeBar.name]: NoticeBar,
  },
  created() {
    // 如果cookie中有token 那么拿出来，放到本地存储中吧
    function getCookie(name)
    {
      var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
      if(arr=document.cookie.match(reg))
        return unescape(arr[2]);
      else
        return null;
    }
    let cookieToken =   getCookie('token')
    if (cookieToken !== null){
      console.log('从cookie拿token放到本地储存中')
      setToken(cookieToken)
    }else{
      console.log('cookie中没token')
    }
    console.log(cookieToken)
    let token = getToken()
    console.log('TOKEN==>', token);
    if (token.length == 0 && isWeiXin()) {
      this.wxlogin()
    }
    // 1判断是不是微信
    if (isWeiXin()) {
      console.log('是微信')
      this.$API.user.getUserInfo().then(res => {
        console.log('res', res)
        if (res.errno == 1) {
           this.wxlogin()
        }
      })
      this.$API.services.jssdk().then(res => {
        wx.config(res.data);
        wx.error(function (res) {
          console.log(res)
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
        wx.checkJsApi({
          jsApiList: ['onMenuShareTimeline'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            console.log('222', res)
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          }
        });
        this.wxshare()
      })
    } else {
      console.log('不是微信')

    }
    //1.1是微信就拉用户信息接口
    //2 用户信息接口为请登录则跳转到微信
  },
  mounted() {

  },
  methods: {

    wxlogin() {
      let REDIRECT_URI = process.env.VUE_APP_APIHOST + 'user/login/wechatmp&returnurl=' + encodeURIComponent(window.location.href);

      console.log('REDIRECT_URI')
      console.log(REDIRECT_URI)
     window.location = REDIRECT_URI
    },
    wxshare() {
      let that = this
      let shareData = {
        title: '深信计协｜免费修电脑-查课表-查成绩-宿舍用电', // 分享标题
        desc: '查课表，查成绩，查宿舍用电，免费修电脑就找深信计协', // 分享描述
        link: 'https://stu.sziitjx.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://stu.sziitjx.cn/logo.jpeg', // 分享图标
        success: function () {
          console.log('分享回调')
          // 设置成功
          that.$API.services.shared()
        },
      }
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateTimelineShareData(shareData)
        wx.updateAppMessageShareData(shareData)
      });
    }
  }
}
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  height: 100%;
  /*background-color: #f1f1f1;*/
}


</style>


