import axios from '../util/request'

export default {
    getPayInfo() {
        return axios.get('/user/user/userinfo')
    },
    // 红包
    getRedPackInfo() {
        return axios.get('/score/redpack/RedPackInfo')
    },

    // 打赏赞助
    bookFixPayInfo(id, price) {
        return axios.post('/score/pay/bookFixPayInfo', {id, fee: price})
    },
}
