<template>
    <div class="content">
        <Title title="准考证存储"/>
        <md-button type="default" @click="chooseImg">选择图片（可选）</md-button>
        <md-button type="primary">保存</md-button>
        <md-button type="primary" plain>返回</md-button>
    </div>
</template>

<script>
    export default {
        name: "Ticket",
        data() {
            return {
                image: ''
            }
        },
        methods: {
            chooseImg() {
                let that = this
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (res) {
                        let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                        console.log(res)
                        that.images = localIds[0]
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
