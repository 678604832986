<template>
    <div>
        <div class="content">
            <Title title="用电查询"></Title>
            <md-field title="找到你的房间" brief="绑定房间每日接收用电提醒">

                <p slot="footer">信息仅供参考</p>
                <!--                <md-drop-menu :data="buildings" />-->

                <md-field-item solid title="乐群楼" placeholder="选择楼宇" @click="isSelectorShow = true" :content="build"/>
                <md-field-item solid title="房间号" :content="room" placeholder="例：1501"
                               @click="isKeyBoardShow = !isKeyBoardShow"/>
                <md-button type="primary" style="margin-top: 10px" @click="find" :loading="loading">查询</md-button>
                <md-button type="primary" plain class="btn" style="margin-top: 10px"  @click="$router.go(-1)">返回</md-button>
            </md-field>
            <md-number-keyboard
                    v-model="isKeyBoardShow"
                    hide-dot
                    @enter="onNumberEnter"
                    @delete="onNumberDelete"
            ></md-number-keyboard>
            <md-selector
                    v-model="isSelectorShow"
                    default-value="A2"
                    :data="buildings"
                    max-height="320px"
                    title="乐群楼"
                    large-radius
                    @choose="onSelectorChoose"
            ></md-selector>

        </div>
        <result ref="result" :battery="battery" @bindRoom="bindRoom"></result>
        <WechatDialog :banner="banner" ref="wechatd"></WechatDialog>
    </div>
</template>

<script>
    import {Button, Dialog, DropMenu, Field, FieldItem, Icon, NumberKeyboard, Selector, Toast} from 'mand-mobile'
    import Result from "../components/Battery/Result";

    export default {
        name: "Battery",
        components: {
            Result,
            [Button.name]: Button,
            [DropMenu.name]: DropMenu,
            [Field.name]: Field,
            [FieldItem.name]: FieldItem,
            [Icon.name]: Icon,
            [NumberKeyboard.name]: NumberKeyboard,
            [Selector.name]: Selector,
            [Dialog.name]: Dialog,
        },
        data() {
            return {
                isKeyBoardShow: false,
                room: '',
                build: '',
                battery: {
                    SSMC: '',
                    SYDL: '',
                    JZSJ: '',
                },
                loading:false,
                isSelectorShow: false,
                buildings: [
                    {
                        value: 'A1栋',
                        text: 'A1栋',
                    },
                    {
                        value: 'A2栋',
                        text: 'A2栋',
                    },
                    {
                        value: 'B1栋',
                        text: 'B1栋',
                    },
                    {
                        value: 'B2栋',
                        text: 'B2栋',
                    },
                    {
                        value: 'C1栋',
                        text: 'C1栋',
                    },
                    {
                        value: 'C2栋',
                        text: 'C2栋',
                    },
                    {
                        value: 'C3栋',
                        text: 'C3栋',
                    },
                    {
                        value: 'C4栋',
                        text: 'C4栋',
                    },
                    {
                        value: 'D1栋',
                        text: 'D1栋',
                    },
                    {
                        value: 'D2栋',
                        text: 'D2栋',
                    },
                    {
                        value: 'D3栋',
                        text: 'D3栋',
                    },
                    {
                        value: 'D4栋',
                        text: 'D4栋',
                    },
                    {
                        value: 'E1栋',
                        text: 'E1栋',
                    },
                    {
                        value: 'E2栋',
                        text: 'E2栋',
                    },
                    {
                        value: 'E3栋',
                        text: 'E3栋',
                    },

                ],
                banner: ''
            }
        },
        methods: {
            // 查找电量
            find() {
                this.isKeyBoardShow = false
                if (this.room.length ==0 || this.build.length.length == 0){
                    Toast.failed('请输入完整房间信息')
                    return
                }
                this.loading = true
                this.$API.battery.getElectricityInfo(this.build + this.room).then(res => {
                    this.battery = res.data
                    this.$refs.result.onShow()
                    this.loading = false
                }).catch(()=> this.loading = false)
            },
            // 选择楼宇
            onSelectorChoose(item) {
                console.log(item)
                this.build = item.value
            },
            onNumberEnter(val) {
                if (this.room.length >= 4) {
                    return
                }
                this.room += val
            },
            onNumberDelete() {
                if (this.room === '') {
                    return
                }
                this.room = this.room.substr(0, this.room.length - 1)
            },
            bindRoom(){
                this.$refs.result.binDing()
                this.$API.services.bindRoom(this.build+this.room).then(res=>{
                    if (res.errno == 0) {
                        Toast.succeed('绑定成功')
                    } else if (res.errno == 474) {
                        Toast.failed('绑定失败:' + res.msg)
                        this.banner = '/wechat/fuwuhao8cm.png'
                        setTimeout(() => this.$refs.wechatd.openIt(), 1000)
                    } else {
                        Toast.failed(res.msg)

                    }
                    this.$refs.result.onIconConfirm()
                    console.log(res)
                }).catch(()=>this.$refs.result.onIconConfirm())
            },
        }

    }
</script>

<style scoped>

</style>
