<template>
  <div class="menusrow">
    <div v-for="(item,index) in menus" :key="index">
      <h5 class="servicesTitle">{{ item.title }}</h5>
      <masonry
          :cols="3"
          type="flex"
          justify="space-around"
      >
        <div class="menu" v-for="(menu,indexMenu) in item.menu" :key="indexMenu"
             @click="action(menu) ">
          <md-icon
              :name="menu.icon"
              size="lg"
              svg
          ></md-icon>
          <p>{{ menu.name }} </p>
        </div>
      </masonry>
      <md-dialog
          :title="dialogData.title"
          v-model="dialogData.isOpen"
          :btns="dialogData.btns"
      >
        <div class="dialog-banner" slot="header" v-if="dialogData.banner.length !=0">
          <img :src="dialogData.banner"
               :alt="dialogData.title">
        </div>
        {{ dialogData.msg }}
      </md-dialog>
    </div>
    <WechatDialog msg="请先关注公众号" :banner="banner" ref="wechatd"></WechatDialog>

  </div>


</template>

<script>
import {Icon} from 'mand-mobile'

export default {
  name: "IndexMenus",
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      banner: '/wechat/fuwuhao8cm.png',
      menus: [
        {
          title: '计协服务', menu: [
                {
                    name: '免费修电脑',
                    icon: 'banshou',
                    url: '/bookfix',
                    urltype: 'orign',
                    click: 'router',
                },
                {
                    name: '课表查询/上课提醒',
                    icon: 'xieyixinxi',
                    url: '/subject',
                    urltype: 'orign',
                    click: 'router'
                },
            {
              name: '电费充值',
              icon: 'dianliang',
              url: 'https://weixiao.qq.com/apps/school-auth/login?school_code=4144012957&app_key=9F8705A99F882808&redirect_uri=http://cgdzchat.com/sdms-pay-weixiao/service/weixin/getOpenId',
              urltype: 'out',
              click: 'router',
            },
            {
              name: '校园网密码修改',
              icon: 'bookroom',
              url: 'https://auth.sziit.edu.cn/retrieve-password/passwordMobile/index.html?t=1650765728881#/home',
              urltype: 'out',
              click: 'router',
            },
            {
              name: 'WIFI路由器设置',
              icon: 'routericon',
              url: 'https://mp.weixin.qq.com/s/w9lBQ2dsYblZXSm9D9s3Pg',
              urltype: 'out',
              click: 'router'
            },
            {
              name: '加入我们',
              icon: 'join',
              url: 'http://sziitjx2020.mikecrm.com/GXFToan',
              urltype: 'out',
              click: 'router'
            },
            /*   {
                   name: '微信红包封面',
                   icon: 'redpackage',
                   url: '/redpackage',
                   urltype: 'orign',
                   click:'router',
                   // click: 'dialog',
                   dialogData: {
                       isOpen: true,
                       msg: '奖品发完啦',
                       title: '活动已结束',
                       banner: '',
                       btns: [
                           {
                               text: '知道啦',
                               handler: this.isOpen = false,
                           },
                       ],
                   }
               },*/

            {
              name: '圣诞节头像',
              icon: 'christmas',
              url: '/christmas',
              urltype: 'orign',
              click: 'router'
            },
            /* {
               name: '机房自习室预约',
               icon: 'bookroom',
               url: '/bookroom',
               urltype: 'orign',
               click:'router',
             },*/
            {
              name: '准考证存储',
              icon: 'jiekuanrenshenfenzhengxinxixiugai',
              url: '/ticket',
              urltype: 'orign',
              click: 'dialog',
              dialogData: {
                isOpen: true,
                msg: '敬请期待',
                title: '敬请期待',
                banner: '',
                btns: [
                  {
                    text: '知道啦',
                    handler: this.isOpen = false,
                  }
                ]
              }
            },
            {
              name: '成绩查询',
              icon: 'chengji',
              url: '/score2',
              // url: 'https://ehall.sziit.edu.cn/publicapp/sys/cjcxapp/mobile/gradesQuery/index.html',
              urltype: 'orign',
              click: 'router',
              // click: 'dialog',
              dialogData: {
                isOpen: true,
                msg: '敬请期待',
                title: '敬请期待',
                banner: '',
                btns: [
                  {
                    text: '知道啦',
                    handler: this.isOpen = false,
                  },
                ],
              }
            },
            {
              name: '考试时间查询',
              icon: 'exam1',
              url: '/exam',
              urltype: 'orign',
              click: 'router',
            },
            {
              name: '宿舍用电查询',
              icon: 'dianliang',
              url: '/battery',
              urltype: 'orign',
              click: 'router',
            },
            {
              name: '计算机等级练习',
              icon: 'lianxi',
              click: 'dialog',
              dialogData: {
                isOpen: true,
                msg: '微信搜索"计算机等级练习生"小程序即可免费获取',
                title: '计算机等级练习',
                banner: '',
                btns: [
                  {
                    text: '知道啦',
                    handler: this.isOpen = false,
                  },
                ],
              }
            },
            {
              name: '专插本资料',
              icon: 'exam',
              urltype: 'orign',
              click: 'dialog',
              dialogData: {
                isOpen: true,
                msg: '微信搜索"专插本练习"小程序即可免费获取！',
                title: '专插本资料',
                banner: '',
                btns: [
                  {
                    text: '知道啦',
                    handler: this.isOpen = false,
                  },
                ],
              }
            },
            {
              name: '学校校历',
              icon: 'date',
              url: '/subject',
              urltype: 'orign',
              click: 'dialog',
              dialogData: {
                isOpen: true,
                msg: '公众号内回复校历',
                title: '学校校历',
                banner: '',
                btns: [
                  {
                    text: '知道啦',
                    handler: this.isOpen = false,
                  },
                ],
              }
            },

            /*   {
                   name: '加入我们',
                   icon: 'join',
                   url: '/subject',
                   urltype: 'orign',
                   click: 'dialog',
                   dialogData: {
                       isOpen: true,
                       msg: '请添加微信客服690712575',
                       title: '加入我们',
                       banner: '',
                       btns: [
                           {
                               text: '知道啦',
                               handler: this.isOpen = false,
                           },
                       ],
                   }
               },*/
            {
              name: '联系我们',
              icon: 'weikefu',
              url: '/subject',
              urltype: 'orign',
              click: 'dialog',
              dialogData: {
                isOpen: true,
                msg: '请添加微信客服690712575，或致电075533122297',
                title: '联系客服',
                banner: '',
                btns: [
                  {
                    text: '知道啦',
                    handler: this.isOpen = false,
                  },
                ],
              }
            },
            /*  {
                  name: '手机电脑外网抢课',
                  icon: 'qiangke',
                  url: '/subject',
                  urltype: 'orign',
                  click: 'func',
                  func:()=>{
                      this.$API.user.hasUser().then(res=>{
                          if(res.errno == 474){
                              // 关注
                              //  弹出框
                              this.banner = '/wechat/fuwuhao8cm.png'
                              this.$refs.wechatd.openIt()
                          }else{
                              // 跳转
                              window.location = 'http://113.106.49.143/'
                          }
                      })
                  },
                  dialogData: {
                      isOpen: true,
                      msg: '请添加微信客服690712575，或致电075533122297',
                      title: '联系客服',
                      banner: '',
                      btns: [
                          {
                              text: '知道啦',
                              handler: this.isOpen = false,
                          },
                      ],
                  }
              },
*/
          ]
        },
        {
          title: '校园服务', menu: [
            {
              name: '旧教务系统',
              icon: 'jiaowu',
              url: 'http://jwgl.sziit.edu.cn',
              urltype: 'out',
              click: 'router'
            },
            {
              name: '新教务系统',
              icon: 'jiaowu_1',
              url: 'http://xjwgl.sziit.edu.cn',
              urltype: 'out',
              click: 'router'
            },
            {
              name: '校园卡充值',
              icon: 'daikuanyewu',
              url: 'http://weixincard.sziit.edu.cn/wechat/login/wechat_login.html',
              urltype: 'out',
              click: 'router'
            }
            ,
          ],
        },
        {
          title: '其他服务', menu: [

            {
              name: '义工时查询',
              icon: '137yigong',
              url: 'http://v.sva.org.cn/',
              urltype: 'out',
              click: 'router'
            },

            {
              name: 'NCRE计算机等级',
              icon: 'diannao',
              url: 'http://ncre.neea.edu.cn/',
              urltype: 'out',
              click: 'router'
            }
            ,
            {
              name: 'CET英语四六级',
              icon: 'english',
              url: 'http://cet.neea.edu.cn/cet/',
              urltype: 'out',
              click: 'router'
            },
            {
              name: 'CET英语四六级准考证',
              icon: 'english',
              url: 'http://cet-bm.neea.cn/Home/QueryTestTicket',
              urltype: 'out',
              click: 'router'
            }
          ],
        }

      ],
      dialogData: {
        isOpen: false,
        msg: 'msg',
        title: 'title',
        banner: "",
        btns: [
          {
            text: '知道啦',
            handler: this.isOpen = false,
          },
        ],
      }
    }
  },
  mounted() {
    /* let dialogData = {
         isOpen: false,
         msg: '本活动由深信学长驾校赞助',
         title: '用计协万事屋，领深信专属红包封面！',
         // banner: '/wechat/guanggao.png',
         btns: [
             {
                 text: '知道啦',
                 handler: this.isOpen = false,
             },
         ],
     }
     this.dialogData = dialogData*/
  },
  methods: {
    action(menu) {
      console.log(menu)

      if (menu.click == 'router') {
        this.goTo(menu.url, menu.urltype)

      } else if (menu.click == 'dialog') {
        this.dialogData = menu.dialogData
      } else if (menu.click == 'func') {
        menu.func()
      }
    },
    dialog(menu) {
      console.log(menu)
      this.dialogData.isOpen = false
      this.dialogData.msg = 'msg'
      this.dialogData.title = 'title'
      this.dialogData.banner = ""
      this.dialogData.isOpen = true

    }

    /*goTo(url, urltype) {
        if (urltype == 'orign') {
            this.$router.push(url)
        } else {
            window.location = url
        }
    }*/
  }
}
</script>

<style lang="stylus" scoped>
.dialog-banner
  img
    display block
    width 100%

.menusrow
  margin 0 auto
  text-align center;
  /*border-style:  solid;*/
  border-radius: 40px
  padding 30px 10px
  box-shadow 8px 8px 8px rgba(0, 0, 0, 0.2)

  background #ffffff

  p
    font-size: 20px;

  .servicesTitle
    margin 10px 30px 0px 30px
    letter-spacing 6px
    font-size: 50px
    /*padding-left 30px*/
    text-align left
    position relative
    top 20px
    background linear-gradient(to top, #8672f2c3 36%, transparent 26% 100%)
    display table


</style>
