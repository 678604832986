<template>
    <div>
        <keep-alive :include="include">
            <router-view></router-view>
        </keep-alive>
        <p id="footer">Copyright©深信Aiy计算机协会&nbsp;2016-{{new Date().getFullYear()}} <br>自动构建时间：{{BUILDTEIME}}</p>

        <StuNavBar></StuNavBar>
    </div>
</template>

<script>
    import StuNavBar from "../components/StuNavBar2";

    export default {
        name: "home",
        mounted() {
            this.BUILDTEIME = process.env.VUE_APP_BUILDTEIME

        },
        components: {
            StuNavBar
        },
        data() {
            return {
                include: ['User', 'Home', 'Index'],
                BUILDTEIME: ''
            }
        },
        methods: {}
    };
</script>
<style scoped>
    #footer {
        font-size: 20px;
        text-align: center;
        margin: 10px 0 10px 0;
        letter-spacing: 3px;

    }
</style>
