<template>
  <div style="background: rgb(249 250 251)">
    <WechatDialog :banner="banner" ref="wechatd"></WechatDialog>
    <div
        class="md-example-child md-example-child-field md-example-child-field-0"
    >
      <md-field title="知行楼2-501机房预约" :brief="bookBrief">
        <!--        <div class="action-container" slot="action">
                  预约记录 <md-icon name="rectangle"></md-icon>
                </div>-->
        <md-input-item
            title="真实姓名"
            type="text"
            align="right"
            v-model="name"
            placeholder="点击输入"
            is-highlight
        ></md-input-item>
        <md-input-item
            align="right"
            title="手机号"
            type="phone"
            v-model="mobile"
            is-highlight
            placeholder="11位手机号"
        ></md-input-item>
        <md-input-item
            align="right"
            title="学号"
            type="number"
            :maxlength="10"
            v-model="sid"
            is-highlight
            placeholder="请输入学号"
        ></md-input-item>
        <md-input-item
            align="right"
            title="班级"
            v-model="classes"
            is-highlight
            placeholder="如20电商2-1"
        ></md-input-item>
      </md-field>
    </div>

    <div>
      <md-action-bar :actions="actionData"></md-action-bar>
    </div>
  </div>
</template>

<script>
import {
  ActionBar,
  RadioBox,
  Radio,
  Dialog,
  InputItem,
  Field,
  TextareaItem,
  FieldItem,
  Icon,
  Toast,
  Button,
  CheckBox
} from 'mand-mobile'

export default {
  name: 'BookRoom',
  components: {
    [CheckBox.name]: CheckBox,
    [ActionBar.name]: ActionBar,
    [Radio.name]: Radio,
    [RadioBox.name]: RadioBox,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [FieldItem.name]: FieldItem,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [InputItem.name]: InputItem,
    [TextareaItem.name]: TextareaItem
  },
  data () {
    return {
      // 学号
      sid:'',
      // 班级
      classes:'',
      // 姓名
      name:'',
      // 手机号
      mobile:'',
      nameclass: '',
      sex: '',
      telephone: '',
      wechatid: '',
      problem: '',
      value: '',
      banner: '',
      pctype: '',
      bookBrief:
          '每日10点起预约，名额13人/天，机房开放时间周一到周四：19:00 - 21:30，详情加微信690712575。',
      disabledBtn: false,
      loaded: false,
      actionData: [
        {
          text: '取消',
          onClick: () => {
            this.$router.go(-1)
          }
        },
        {
          text: '提交',
          disabled: false,
          loading: this.loaded,
          onClick: this.handleBook
        }
      ]
    }
  },
  beforeMount () {
    this.$API.bookroom.overview().then(res => {
      if (res.errno == 0) {
        this.bookBrief += `总等候人数：${res.data.total},当日可预约人数：${res.data.rest}`
      }
    })
  },
  methods: {
    handleCancel () {
      this.$route.go(-1)
    },
    // 预约
    handleBook () {
      this.actionData[1].loading = true
      this.$API.bookroom
          .bookroom(
              this.name,
              this.mobile,
              this.sid,
              this.classes
          )
          .then(res => {
            this.actionData[1].loading = false
            if (res.errno == 0) {
              this.actionData[1].disabled = true
              Dialog.succeed({
                title: '预约成功',
                content: '请按时间进入自习室！',
                confirmText: '确定',
                closable: false
              })
            } else {
              Toast.failed(res.msg)
            }
          })
    }
  }
}
</script>

<style lang="stylus" scoped>
.md-field-header
  margin-bottom 0
.md-example-child-field-0
  .md-field-action
    height 36px
    align-items flex-end
    .action-container
      display flex
      align-items center
      .md-icon
        margin-left 10px
        color #C5CAD5
        font-size 16px
        width auto
        height auto
        line-height normal
.demo2-footer
  display flex
  justify-content space-between
  color #999
  font-size 24px
</style>
