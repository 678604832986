<template>
  <div>
    <md-tabs>
      <md-tab-pane name="p1" label="新增课程1">
        <div class="content">
          <md-field>
            <md-input-item
              ref="input4"
              title="*课程名称"
              placeholder="（必填）请输入课程名称"
              align="right"
              is-highlight
              clearable
            ></md-input-item>
            <md-input-item
              ref="input4"
              title="任课老师"
              placeholder="请输入任课老师"
              align="right"
              is-highlight
              clearable
            ></md-input-item>
            <md-input-item
              ref="input4"
              title="教室名称"
              placeholder="请输入教室名称"
              align="right"
              is-highlight
              clearable
            ></md-input-item>
          </md-field>

          <div class="timeSelectBox">
            <div class="operationBar">
              <div class="title">上课时间</div>
              <div class="operation">
                <md-button size="small" type="primary" @click="addTimeItem">添加</md-button>
              </div>
            </div>
            <div class="timeBoxContent">
              <div class="timeBoxItem" v-for="(item, index) in timeList" :key="index">
                <div class="operationBar">
                  <div class="title">时间-{{ index + 1 }}</div>
                  <div class="operation">
                    <md-button size="small" type="warning" @click="deleteTimeItem(index)">删除</md-button>
                  </div>
                </div>
                <div class="timeBoxItemContent">
                  <md-field-item
                    title="星期"
                    :content="item.week"
                    @click="openSelector(index, 'week')"
                    arrow
                    solid
                  />
                  <md-field-item
                    title="上课：第"
                    :content="item.startNode"
                    @click="openSelector(index, 'startNode')"
                    addon="节上课"
                    arrow
                    solid
                    :no-border="true"
                  />
                  <md-field-item
                    title="下课：第"
                    :content="item.endNode"
                    @click="openSelector(index, 'endNode')"
                    addon="节下课"
                    arrow
                    solid
                  />
                  <md-field-item
                    title="开始周：第"
                    :content="item.startWeek"
                    @click="openSelector(index, 'startWeek')"
                    addon="周开始"
                    arrow
                    solid
                    :no-border="true"
                  />
                  <md-field-item
                    title="结束周：第"
                    :content="item.endWeek"
                    @click="openSelector(index, 'endWeek')"
                    addon="周结束"
                    arrow
                    solid
                  />
                  <md-field-item title="单双周" solid>
                    <md-radio name="1" v-model="item.isSingle" label="单周" inline />
                    <md-radio name="2" v-model="item.isSingle" label="双周" inline />
                  </md-field-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </md-tab-pane>
      <md-tab-pane name="p2" label="课表预览"> </md-tab-pane>
    </md-tabs>

    <md-action-bar class="action-bar" :actions="actionData"></md-action-bar>
    <md-selector
      v-model="weekSelectorShow"
      :data="weeks"
      max-height="320px"
      title="选择星期几"
      large-radius
      @choose="(choose) => chooseSelector(choose, 'week')"
    ></md-selector>
    <md-selector
      v-model="startNodeSelectorShow"
      :data="courseNodeOptions"
      max-height="320px"
      title="选择第几节上课"
      large-radius
      @choose="(choose) => chooseSelector(choose, 'startNode')"
    ></md-selector>
    <md-selector
      v-model="endNodeSelectorShow"
      :data="courseNodeOptions"
      max-height="320px"
      title="选择第几节下课"
      large-radius
      @choose="(choose) => chooseSelector(choose, 'endNode')"
    ></md-selector>
    <md-selector
      v-model="startWeekSelectorShow"
      :data="courseWeekOptions"
      max-height="320px"
      title="选择第几周开始"
      large-radius
      @choose="(choose) => chooseSelector(choose, 'startWeek')"
    ></md-selector>
    <md-selector
      v-model="endWeekSelectorShow"
      :data="courseWeekOptions"
      max-height="320px"
      title="选择第几周结束"
      large-radius
      @choose="(choose) => chooseSelector(choose, 'endWeek')"
    ></md-selector>
  </div>
</template>
<script>
import { Selector, Field, FieldItem, Radio } from "mand-mobile";
export default {
  name: "Subject3",
  components: {
    [Selector.name]: Selector,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Radio.name]: Radio,
  },
  data() {
    return {
      weekSelectorShow: false,
      startNodeSelectorShow: false,
      endNodeSelectorShow: false,
      startWeekSelectorShow: false,
      endWeekSelectorShow: false,
      timeList: [],
      currentTimeItemIndex: 0,
      timeListItemObj: {
        week: "",
        startNode: "",
        endNode: "",
        startWeek: "",
        endWeek: "",
        isSingle: "",
      },
      actionData: [
        {
          text: "上课提醒",
          onClick: this.handleClick,
        },
        {
          text: "新增课程",
          onClick: this.handleClick,
        },
      ],
      courseNodeOptions: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
      ],
      courseWeekOptions: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
      ],
      weeks: [
        { value: 1, text: "星期一" },
        { value: 2, text: "星期二" },
        { value: 3, text: "星期三" },
        { value: 4, text: "星期四" },
        { value: 5, text: "星期五" },
        { value: 6, text: "星期六" },
        { value: 7, text: "星期日" },
      ],
    };
  },
  methods: {
    handleClick() {},
    // 添加上课时间
    addTimeItem() {
      this.timeList.push(JSON.parse(JSON.stringify(this.timeListItemObj)));
    },
    // 删除上课时间
    deleteTimeItem(index) {
      this.timeList.splice(index, 1);
    },
    // 打开选择器界面
    openSelector(index, type) {
      this.currentTimeItemIndex = index;
      switch (type) {
        case "week":
          this.weekSelectorShow = true;
          break;
        case "startNode":
          this.startNodeSelectorShow = true;
          break;
        case "endNode":
          this.endNodeSelectorShow = true;
          break;
        case "startWeek":
          this.startWeekSelectorShow = true;
          break;
        case "endWeek":
          this.endWeekSelectorShow = true;
          break;
      }
    },
    // 选择器选择事件
    chooseSelector(choose, type) {
      switch (type) {
        case "week":
          this.timeList[this.currentTimeItemIndex].week = choose.value;
          break;
        case "startNode":
          this.timeList[this.currentTimeItemIndex].startNode = choose.value;
          break;
        case "endNode":
          this.timeList[this.currentTimeItemIndex].endNode = choose.value;
          break;
        case "startWeek":
          this.timeList[this.currentTimeItemIndex].startWeek = choose.value;
          break;
        case "endWeek":
          this.timeList[this.currentTimeItemIndex].endWeek = choose.value;
          break;
      }
    },
  },
};
</script>

<style scoped>
.action-bar {
  position: fixed;
  bottom: 0;
}
.field-row {
  display: flex;
}

.timeBoxItem {
  margin-bottom: 30px;
}

.operationBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.operationBar .title {
  font-size: 32px;
  font-weight: bold;
}

.timeSelectBox > .operationBar {
  margin-bottom: 36px;
}
.timeSelectBox > .operationBar > .title {
  font-size: 48px;
  font-weight: bold;
}

.timeBoxItemContent {
  margin-left: 30px;
}
</style>
