import axios from '../util/request'

export default {
    // 绑定房间
    bindRoom(no) {
        return axios.post('/score/services/bindRoom', {no})
    },
    jssdk() {
        return axios.post('/user/login/jssdk', {})
    },
    shared() {
        return axios.get('/score/services/shared')
    },
    choujiang(){
        return axios.get('/score/Choujiang/choujiang')

    },
    choujiangInit(){
        return axios.get('/score/Choujiang/init')

    },


}
