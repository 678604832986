import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Score from '../views/Score.vue'
// import Index from '../views/Index.vue'
import Subject from '../views/Subject3.vue'
import Battery from '../views/Battery.vue'
import Ticket from '../views/Ticket.vue'
import Index from '../views/Index'
import User from '../views/User'
import Redpackage from '../views/Redpackage'
import ErrorPage from '../views/ErrorPage'
import BookFix from '../views/BookFix'
import evaluate from '../views/evaluate.vue'
import Bookroom from '../views/BookRoom.vue'
import Exam from '../views/Exam.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'index',
        component: Index
        // redirect:'/subject',
      },
      {
        path: '/user',
        name: 'User',
        component: User
      }
    ]
  },
  {
    path: '/exam',
    name: 'Exam',
    component: Exam
  },
  {
    path: '/score',
    name: 'Score',
    component: Score
  },
  {
    path: '/bookroom',
    name: 'Bookroom',
    component: Bookroom
  },
  {
    path: '/redpackage',
    name: 'Redpackage',
    component: Redpackage
  },
  {
    path: '/errorpage',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: Ticket
  },
  {
    path: '/battery',
    name: 'Battery',
    component: Battery
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/subject',
    name: 'Subject',
    component: Subject
  },
  {
    path: '/bookfix',
    name: 'bookfix',
    component: BookFix
  },
  {
    path: '/orderdetail/:id',
    name: 'orderdetail',
    component: () => import('../views/OrderDetail.vue')
  },
  {
    path: '/christmas/',
    name: 'christmas',
    component: () => import(/* webpackChunkName: "christmas" */ '../views/Christmas.vue')
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: evaluate
  },
  {
    path: '/orderlist',
    name: 'orderlist',
    // 订单列表
    component: () =>
        import(/* webpackChunkName: "about" */ '../views/OrderList.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },


  {
    path: '/score2',
    name: 'score2',
    component: () =>
        import(/* webpackChunkName: "about" */ '../views/Score2.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
