<template>
  <div>
    <h5><span>{{ title }}</span></h5>
    <h5 style="width:100%;overflow:hidden;white-space:nowrap;"><span class="typing"></span></h5>

  </div>


</template>

<script>
export default {
  name: "Title",
  props: {
    title: {
      defalut: '深圳信息职业技术学院Aiy计算机协会',
      type: String
    }
  }
}
</script>

<style scoped>
h5 {
  margin-left: 30px;
}

h5 span {
  position: relative;
  font-size: 50px;
}

h5 span::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 5px;
  width: 100%;
  height: 20px;
  background: #b3d7ff;
}
</style>
