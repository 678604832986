<template>
  <div class="verfication" ref="verfication">
    <div
      ref="verficationBG"
      class="verficationBG"
      :style="{ backgroundImage: 'url(' + zfduncaptchajs.png1 + ')' }"
    >
      <img
        class="slidingBlockItem"
        ref="slidingBlockItem"
        :style="{ left: `${slidingBarBlockPosition.x}px` }"
        :src="zfduncaptchajs.png2"
        alt=""
      />
    </div>
    <div class="slidingBar" ref="slidingBar">
      <div
        class="slidingBarBlock"
        ref="slidingBarBlock"
        @touchstart="slidingBarBlockTouch"
        @touchend="slidingBarBlockTouchEnd"
        :style="{ left: `${slidingBarBlockPosition.x}px` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slidingBarBlockPosition: {
        x: 0,
        y: 0,
      },
      // 验证码服务端的数据
      zfduncaptchajs: {},
      verificationInfo: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$API.xtgl.zfduncaptchajs().then((res) => {
        this.zfduncaptchajs = res.data;
      });
    },
    slidingBarBlockTouch(e) {
      this.verificationInfo = [];
      this.$refs.slidingBarBlock.style.transition = "";
      this.$refs.slidingBlockItem.style.transition = "";
      document.addEventListener("touchmove", this.slidingBarBlockTouchMove);
    },
    slidingBarBlockTouchMove(e) {
      let slidingBar = this.$refs.slidingBar
      let verfication = this.$refs.verfication
      // console.log('verfication', verfication.getBoundingClientRect())
      if (
        this.$refs.slidingBarBlock.offsetLeft >= 0 &&
        this.$refs.slidingBarBlock.offsetLeft <=
          slidingBar.offsetWidth - this.$refs.slidingBarBlock.offsetWidth
      ) {
        this.slidingBarBlockPosition.x = e.changedTouches[0].clientX - verfication.getBoundingClientRect().left;
      } else if (this.$refs.slidingBarBlock.offsetLeft < 0 ) {
        this.slidingBarBlockPosition.x = 0
      } else if (this.$refs.slidingBarBlock.offsetLeft > slidingBar.offsetWidth - this.$refs.slidingBarBlock.offsetWidth ) {
        this.slidingBarBlockPosition.x = slidingBar.offsetWidth - this.$refs.slidingBarBlock.offsetWidth
      }
    },
    slidingBarBlockTouchEnd(e) {
      document.removeEventListener("touchmove", this.slidingBarBlockTouchMove);
      this.$refs.slidingBarBlock.style.transition = "left 0.5s";
      this.$refs.slidingBlockItem.style.transition = "left 0.5s";
      this.slidingBarBlockPosition.x = 0;
      let verifyInfo = {
        x: parseInt(e.changedTouches[0].clientX - this.$refs.verfication.getBoundingClientRect().left),
        rtk: this.zfduncaptchajs.rtk,
        t: this.zfduncaptchajs.t,
        c: this.zfduncaptchajs.c
      }
      this.$emit("verify", verifyInfo);
    },
  },
};
</script>

<style scoped>
.verficationBG {
  height: 200px;
  width: 304px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.verficationBG .slidingBlockItem {
  position: absolute;
  height: 100%;
  width: 72px;
}

.slidingBar {
  height: 40px;
  background-color: #80ffff;
  position: relative;
  overflow: hidden;
}

.slidingBar .slidingBarBlock {
  position: absolute;
  height: 100%;
  width: 50px;
  background-color: rgb(189, 67, 67);
}
</style>
