import axios from '../util/request'

export default {
    //下单
    bookfix(nameclass, mobile, wechat, question, devices_name) {
        return axios.post('/xiudehao/client/addOrder', {nameclass, mobile, wechat, question, devices_name})
    },
    //  查询
    overview() {
        return axios.get('/xiudehao/client/overview')
    },
    //订单列表
    orderList() {
        return axios.get('/xiudehao/client/myorder')
    },
    // 订单详情
    orderDetail(id) {
        return axios.get('/xiudehao/client/orderDetail', {params: {id}})
    },


}
