import subject from './subject'
import user from './user'
import battery from './battery'
import pay from './pay'
import services from './services'
import score from './score'
import fix from './fix'
import bookroom from './bookroom'
import xtgl from './xtgl'


export default {
    subject,
    user,
    battery,
    services,
    pay,
    fix,
    score,
    bookroom,
    xtgl,
}
