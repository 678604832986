<template>
  <div style="background: rgb(249 250 251)">
    <WechatDialog :banner="banner" ref="wechatd"></WechatDialog>
    <div
      class="md-example-child md-example-child-field md-example-child-field-0"
    >
      <md-field title="深信计算机协会电脑免费维修" :brief="bookBrief">
        <div class="action-container" slot="action" @click="$router.push('/orderlist')">
          预约记录查看
        </div>
        <md-input-item
          title="真实姓名"
          type="text"
          align="right"
          v-model="nameclass"
          placeholder="点击输入"
          is-highlight
        ></md-input-item>
        <md-input-item
          align="right"
          title="手机号"
          type="phone"
          v-model="telephone"
          is-highlight
          placeholder="11位手机号"
        ></md-input-item>
        <md-input-item
          title="微信号"
          v-model="wechatid"
          brief="请确保手机号畅通与微信号设置为可搜索"
          is-highlight
          placeholder="用于维修人员与你进行联系"
          align="right"
        ></md-input-item>
        <md-input-item
            class="mb-2"
            disabled
            title="电脑类型"
            is-highlight
        >
          <div class="input-operator" slot="right">
            <md-check-box
                name="台式"
                v-model="pctype"
                label="台式"
                style="margin-right: 8px"
            />
            <md-check-box
                name="笔记本/平板"
              v-model="pctype"
              label="笔记本/平板"
            />
          </div>
        </md-input-item>
        <md-textarea-item
          ref="demo2"
          class="demo2"
          is-highlight
          :autosize="true"
          v-model="problem"
          :max-length="150"
          :max-height="150"
          title="电脑问题"
          placeholder="电脑遇到的问题，台式还是笔记本，描述信息在200字以内"
        >
          <template slot="footer">
            <p class="demo2-footer">
              <span class="demo2-footer-left" v-if="problem.length"
                >{{ problem.length }}/100</span
              >
              <span v-else></span>
              <span class="demo2-footer-right">深信Aiy计算机协会</span>
            </p>
          </template>
        </md-textarea-item>
      </md-field>
    </div>

    <div>
      <md-action-bar :actions="actionData"></md-action-bar>
    </div>
  </div>
</template>

<script>
import {
  ActionBar,
  RadioBox,
  Radio,
  Dialog,
  InputItem,
  Field,
  TextareaItem,
  FieldItem,
  Icon,
  Toast,
  Button,
  CheckBox
} from 'mand-mobile'

export default {
  name: 'BookFix',
  components: {
    [CheckBox.name]: CheckBox,
    [ActionBar.name]: ActionBar,
    [Radio.name]: Radio,
    [RadioBox.name]: RadioBox,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [FieldItem.name]: FieldItem,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [InputItem.name]: InputItem,
    [TextareaItem.name]: TextareaItem
  },
  data () {
    return {
      nameclass: '',
      sex: '',
      telephone: '',
      wechatid: '',
      problem: '',
      value: '',
      banner: '',
      pctype: '',
      bookBrief:
        '公益服务，维修时效性不能保证敬请谅解！如果疑问请与微信690712575联系。',
      disabledBtn: false,
      loaded: false,
      actionData: [
        {
          text: '取消',
          onClick: () => {
            this.$router.go(-1)
          }
        },
        {
          text: '提交',
          disabled: false,
          loading: this.loaded,
          onClick: this.handleBook
        }
      ]
    }
  },
  beforeMount () {
    this.$API.fix.overview().then(res => {
      if (res.errno == 0) {
        this.bookBrief += `总等候人数：${res.data.total},`
      }
    })
  },
  methods: {
    handleCancel () {
      this.$route.go(-1)
    },
    // 预约
    handleBook () {
      this.actionData[1].loading = true
      this.$API.fix
        .bookfix(
          this.nameclass,
          this.telephone,
          this.wechatid,
          `#${this.pctype}# ${this.problem}`,
          this.pctype
        )
        .then(res => {
          this.actionData[1].loading = false
          if (res.errno == 0) {
            this.actionData[1].disabled = true
            Dialog.succeed({
              title: '预约成功',
              content: '很快就有维修人员与您取得联系！',
              confirmText: '确定',
              closable: false
            })
          } else {
            Toast.failed(res.msg)
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.md-field-header
    margin-bottom 0
.md-example-child-field-0
    .md-field-action
        height 36px
        align-items flex-end
        .action-container
            display flex
            align-items center
            .md-icon
                margin-left 10px
                color #C5CAD5
                font-size 16px
                width auto
                height auto
                line-height normal
.demo2-footer
    display flex
    justify-content space-between
    color #999
    font-size 24px
</style>
