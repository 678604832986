// 一些公共方法
import {Button, Dialog} from 'mand-mobile'

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
    },
    methods: {
        goTo(url, urltype, tip = '您即将跳转到外部链接，请注意保护个人信息资料') {
            if (urltype == null || urltype == 'orign') {
                this.$router.push(url)

            } else if (urltype == 'out') {
                Dialog.alert({
                    title: '提示',
                    content: tip,
                    closable: true,
                    cancelText: '取消',
                    confirmText: '确定',
                    onConfirm: () =>{
                        let referLink = document.createElement('a');
                        referLink.rel = 'noreferrer';
                        referLink.href = url;
                        document.body.appendChild(referLink);
                        referLink.click();
                    }
                })

            }
        },
    }
}
