import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './assets/common.css'
import './assets/common.styl'
import API from './api'
import Title from './components/Title'
import StuInput from './components/StuInput'

import {Toast, ActionBar, Bill, DetailItem, Steps, Stepper,Agree,
  Button,
  ActivityIndicator,
  CellItem ,
  Field,
  InputItem,
  NumberKeyboard,Tabs, TabPane,
  Popup,
  PopupTitleBar,
  Icon,
  CheckBox} from 'mand-mobile'
import 'mand-mobile/lib/mand-mobile.css'
import './assets/mytheme.css'
import VueMasonry from 'vue-masonry-css'
import commonMotheds from './components/CommonMotheds'
import WechatDialog from './components/WechatDialog.vue'
import './assets/iconfont.css'
import less from 'less'

Vue.prototype.$toast = Toast
Vue.use(less)
Vue.use(VueMasonry)

Vue.component('Title', Title)
Vue.component('StuInput', StuInput)
Vue.component('WechatDialog', WechatDialog)
Vue.component(Popup.name, Popup)
Vue.component(ActivityIndicator.name, ActivityIndicator)
Vue.component(PopupTitleBar.name, PopupTitleBar)
Vue.component(Icon.name, Icon)

Vue.component(TabPane.name, TabPane)
Vue.component(Tabs.name, Tabs)
Vue.component(ActionBar.name, ActionBar)
Vue.component(CellItem .name, CellItem )
Vue.component(Bill.name, Bill)
Vue.component(DetailItem.name, DetailItem)
Vue.component(Steps.name, Steps)
Vue.component(Stepper.name, Stepper)
Vue.component(InputItem.name, InputItem)
 Vue.component(Toast.component.name, Toast.component)
Vue.component(Field.name, Field)
Vue.component(Button.name, Button)
Vue.component(CheckBox.name, CheckBox)
Vue.component(NumberKeyboard.name, NumberKeyboard)
Vue.component(Agree.name, Agree)
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./assets/icon', false, /\.svg$/)
requireAll(req)

Vue.mixin(commonMotheds) //混入公共方法

// Vue.use(mandMobile)
Vue.config.productionTip = false
Vue.prototype.$API = API //请求的API

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
