import axios from '../util/request'

export default {
    getScore(sid, pwd) {
        return axios.post('/index/Score3/doLogin', {sid, pwd})
    },
    // 教务系统的
    getScore2(sid, pwd, errorcount,ticket, randstr,passwordType,action) {
        return axios.post('/score/Score/getScore', {sid, pwd,errorcount,ticket,randstr,passwordType,action})
    },

}
