import axios from '../util/request'

export default {
    getSubject(sid, pwd, passwordType, ispdf) {
        return axios.post('/score/subject/select', {sid, pwd, passwordType, ispdf})
    },
    bindSubject(sid, pwd, push_type, passwordType) {
        return axios.post('/score/services/bindSubject', {sid, pwd, push_type, passwordType})
    }

}
