import { render, staticRenderFns } from "./StuInfo.vue?vue&type=template&id=95d41198&scoped=true&"
import script from "./StuInfo.vue?vue&type=script&lang=js&"
export * from "./StuInfo.vue?vue&type=script&lang=js&"
import style0 from "./StuInfo.vue?vue&type=style&index=0&id=95d41198&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d41198",
  null
  
)

export default component.exports