<template>
    <md-dialog
            icon-svg
            icon="dianliang"
            :closable="true"
            v-model="iconDialog.open"
            :btns="iconDialog.btns"
    >
        房间号：{{battery.SSMC}},剩余电量：{{battery.SYDL}},抄表时间：{{battery.JZSJ}}。「以上信息仅供参考，以电表为准」-绑定房间每天接收用电提醒。
    </md-dialog>
</template>

<script>
    import {Dialog} from 'mand-mobile'

    export default {
        name: "Result",
        props: {
            battery: {
                type: Object
            }
        },
        components: {

            [Dialog.name]: Dialog,
        },
        data() {
            return {
                iconDialog: {
                    open: false,
                    btns: [
                        {
                            text: '知道啦',
                            handler: this.onIconConfirm,
                        },
                        {
                            text: '绑定该房间',
                            handler: this.bindRoom,
                            loading: false,
                            disabled: false,
                        },
                    ],
                },
            }
        },
        methods: {
            binDing(){
                this.iconDialog.btns[1].loading =  true
                this.iconDialog.btns[1].disabled =  true
            },
            onIconConfirm() {
                if (this.iconDialog.btns[1].loading){
                    setTimeout(()=>{
                        this.iconDialog.open = false
                        this.iconDialog.btns[1].loading =  false
                        this.iconDialog.btns[1].disabled =  false
                    },1000)
                }else{
                    this.iconDialog.open = false
                    this.iconDialog.btns[1].loading =  false
                    this.iconDialog.btns[1].disabled =  false
                }


            },
            onShow() {
                this.iconDialog.open = true
            },
            bindRoom(){
                this.$emit('bindRoom')
            }
        }
    }
</script>

<style scoped>

</style>
