<template>
    <div>
        <Title title="深信计协成绩查询" ></Title>
      <div class="md-example-child md-example-child-water-mark-0">

          <div class="content" v-for="(item,index) in score" :key="index">
            <md-water-mark
                class="text-container"
                spacing="10vw"
                :opacity="0.2"
            >
            <md-tag size="large" shape="square" font-color="#FF8843" type="ghost">{{item[0].xm}}的{{item[0].xnmmc}}学年-学期{{item[0].xqmmc}}</md-tag>
            <div>
              <md-field style="background-color: transparent;">
                <md-check-list
                    v-model="avg"
                    iconPosition="left"
                    :options="buildCheckListData(item)"
                >
                  <template slot-scope="{ option, index, selected }">
                    <p :style="option.value.bfzcj < 60 ?  'color:red': '' " class="md-cell-item-title" v-text="`${option.value.kcmc}   成绩：${option.value.bfzcj}`"></p>
                    <p  :style="option.value.bfzcj < 60 ?  'color:red': ''"  class="md-cell-item-brief" v-text="`${option.value.kcxzmc}  学分：${option.value.xf}`"> </p>
                  <md-tag
                        style="display: inline"
                        size="large"
                        shape="fillet"
                        type="fill"
                        fill-color="rgba(255, 91, 96, .1)"
                        font-weight="normal"
                        font-color="#FF5B60"
                        v-if="option.value.jd == 0"
                    >{{ option.value.ksxz }}</md-tag>
                  </template>
                </md-check-list>

              </md-field>
            </div>
              <div slot="watermark" >
                <span > 深信计协-数据仅供参考不作任何证明之用</span>

              </div>
            </md-water-mark>
          </div>

      </div>

        <StuInfo v-show="!isLogin" v-on:login="loginSuccess"></StuInfo>

        <div v-show="isLogin" class="md-example-child md-example-child-action-bar md-example-child-0">
            <md-action-bar :actions="actionBtns"></md-action-bar>
        </div>
    </div>
</template>

<script>
    import StuInfo from "../components/Score/StuInfo";
    import {Tag,CellItem,Field,NoticeBar,Button,CheckList,ActionBar,Toast,Dialog, WaterMark } from 'mand-mobile'
    export default {
        name: "Score",
        components:{
            StuInfo,
            [Tag.name]: Tag,
            [WaterMark.name]: WaterMark,
            [Field.name]: Field,
            [CellItem.name]: CellItem,
            [NoticeBar.name]: NoticeBar,
            [Button.name]: Button,
            [CheckList.name]: CheckList,
            [ActionBar.name]: ActionBar
        },
        data(){
            return {
                avg:[],
                isLogin: false,
                score:[],
                actionBtns:[
                    {
                        text: '重新查询',
                        onClick:()=>{
                            this.isLogin = ! this.isLogin;
                            this.score= []
                            this.avg = []
                        },
                    },
                    {
                        text: '计算加权平均分与绩点',
                        onClick: ()=>{
                            if (this.avg.length == 0){
                                Toast.failed('请勾选成绩')
                                return
                            }else {
                                let scoreTotal = 0
                                let xuefenTotal = 0
                                let jdTotal = 0
                                this.avg.map((item)=>{


                                    if (!isNaN(item.bfzcj)){
                                        let xf = parseFloat(item.xf)
                                        scoreTotal += item.bfzcj*xf
                                        jdTotal += item.jd*xf
                                        xuefenTotal += xf
                                    }
                                })

                               let avg =  Math.floor(scoreTotal/xuefenTotal);
                                let jd = jdTotal/xuefenTotal
                                // Toast.succeed('约等于：'+ avg)
                                Dialog.alert({
                                    title: '非教务系统数据',
                                    content: `加权成绩：${avg}，绩点：${jd.toFixed(2)}`,
                                    cancelText: '取消',
                                    confirmText: '确定',
                                })
                            }
                        },
                    },
                ]
            }
        },
      mounted() {
        // Toast.failed('维护中，期末开放！')
      },
      methods:{
            loginSuccess(data){
                this.isLogin = true
                this.score = data
                Dialog.alert({
                    title: '警告',
                    content: '信息仅供参考，以教务系统显示为准，数据不作任何证明，可能存在偏差性，本网站不对此负责。',
                    cancelText: '取消',
                    confirmText: '确定',
                 })
            },
            buildCheckListData(data){
                let d = []
                data.map((item)=>{
                    d.push({'value':item,label:`${item.kcmc}   成绩：${item.bfzcj}`,brief:`${item.kcxzmc}  学分：${item.xf}`,addon:item.bfzcj})
                })
                return d
            }
        }
    }
</script>

<style lang="stylus" scoped>
.md-example-child-water-mark-0
  .text-container
    background #FFF

</style>
