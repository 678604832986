//本地存储插件
let Lockr = require('lockr')
export function getToken() {
    let token = Lockr.get("token");
    if (token === undefined) return "";
    else return token;
}

export function getWxToken() {
    let token = Lockr.get("wxtoken");
    if (token === undefined) return "";
    else return token;
}

export function setToken(token) {
    return Lockr.set("token", token)

}

export function getLogin() {
    return Lockr.get("stuLogin")
}

export function setLogin(bol) {
    return Lockr.set("stuLogin", bol);
}
export function setOaPwd(pwd) {
    return Lockr.set("oapwd", pwd);
}
export function getOaPwd() {
    return Lockr.get("oapwd");
}
export function setPwd(pwd) {
    return Lockr.set("pwd", pwd);
}

export function getPwd() {
    return Lockr.get("pwd");
}

export function setSid(sid) {
    return Lockr.set("sid", sid);
}

export function getSid() {
    return Lockr.get("sid");
}

export function rmToken() {
    return Lockr.rm("token");
}

export function rmWxToken() {
    return Lockr.rm("wxtoken", {domain: ".sziit.cn"});
}

export function setPasswordType(passwordType) {
    return Lockr.set("passwordType", passwordType);
}

export function getPasswordType() {
    return Lockr.get("passwordType");
}
