import axios from '../util/request'

export default {
    zfduncaptchajs() {
        return axios.get('/score/xtgl/zfduncaptchajs')
    },
    submitZfcaptchaLogin(rtk,x,c,action,sid,pwd,passwordType) {
        return axios.post('/score/xtgl/submitZfcaptchaLogin', {rtk,x,c,action,sid,pwd,passwordType})
    }
}
