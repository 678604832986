<template>
  <div>
    <!--        <Title title="个人中心"></Title>-->

    <div class="headerContent">
      <img :src="avater" id="avatar" />
      <p>{{ nickName }}</p>
    </div>
    <div id="menus">
      <!--            <div class="menu">-->
      <!--<div>
                    <md-icon class="myicon" name="push" svg></md-icon>
                    <span>消息提醒设置</span></div>-->
      <!--            </div>-->
      <div class="menu">
        <div>
          <md-icon class="myicon" name="phone" svg></md-icon>
          <span><a class="iphone" href="tel:075533122297">电话客服</a></span>
          <span class=" right iconfont icon-right"></span>
        </div>
      </div>
      <div class="menu" @click="showWechat(1)">
        <div>
          <span class="myicon iconfont icon-weixin"></span>
          <span>微信服务号</span>
          <span class=" right iconfont icon-right"></span>
        </div>
      </div>
      <div class="menu" @click="showWechat(2)">
        <div>
          <span class="myicon iconfont icon-weixin"></span>
          <span>微信订阅号</span>
          <span class=" right iconfont icon-right"></span>
        </div>
      </div>
    </div>
    <WechatDialog :banner="banner" ref="wechatd"></WechatDialog>
  </div>
</template>

<script>
import commandList from '../components/User/commandList'
import taskList from '../components/User/taskList'
import { Icon } from 'mand-mobile'

export default {
  name: 'User',
  components: {
    [Icon.name]: Icon
  },
  data () {
    return {
      commandList,
      taskList,
      avater: require('@/assets/logo.png'),
      nickName: '深信计协',
      banner: '/wechat/fuwuhao8cm.png'
    }
  },
  beforeMount () {
    this.getUserInfo()
  },
  methods: {
    showWechat (type) {
      console.log(type)
      if (type == 1) {
        this.banner = '/wechat/fuwuhao8cm.png'
      } else {
        this.banner = '/wechat/dingyuehao8cm.jpg'
      }
      this.$refs.wechatd.openIt()
    },
    getUserInfo () {
      this.$API.user.getUserInfo().then(res => {
        if (res.errno == 0) {
          this.nickName = res.data.nickname
          this.avater = res.data.headimgurl
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.headerContent
    height 600px
    background-image linear-gradient(top, #29b3db 0%, #9a5ae8 100%)
    color white
    text-align center
    position relative
    #avatar
        height 150px
        width 150px
        background-color #ffffff
        border-radius 105px
        padding 5px
        /*border 1px solid white*/
        /*border-style:solid;*/
        margin 0 auto
        position relative
        top 150px
    p
        position relative
        top 170px
        font-size 30px


#menus
    height 400px
    background-color #ffffff
    position relative
    top -100px
    border-radius 80px 80px 0 0
    padding  35px

.menu
    position relative
    border-radius 20px
    background-color #f1f1f1
    height 90px
    margin 20px 0
    line-height: 90px !important
    font-size: 30px !important
    background-image linear-gradient(to right,#816ee5,#2db0dc)
    color #fff !important


.myicon
    line-height: 40px !important;
    font-size: 40px !important;
    margin: 0 40px
    color #fff

.iphone
    color #fff

// 三角形

.right
    font-size 30px
    position absolute
    right 20px
</style>
