<template>
  <div class="content">
    <StuInput @stuinput="stuinput" :loading="loading" :inactive="loading"></StuInput>
    <!--    <p style="color: springgreen">服务器开发人员自掏腰包，人数容纳有限处理速度较慢敬请谅解。</p>-->
    <p style="display:none;color: red">密码是教务系统抢课那个密码，默认身份证后六位！不是机房上网密码和今日校园的密码！</p>
    <p style="color: purple">期末查询人数较多，请错峰查询！</p>
    <p style="color: red">{{ tip }}</p>
    <p style="color: red">查询开放时间10：00-22：00，由于服务器网络波动可能出现临时维护。</p>
    <p>深信计协并不会收集保存你的学号密码等信息，所有信息将会存储在本机上。</p>
    <p>所有信息仅供参考，最终以教务系统为准。</p>
    <md-number-keyboard
        ref="stunokeyboard"
        v-model="isKeyBoardShow"
        type="simple"
        @enter="onNumberEnter"
        @delete="onNumberDelete"
    ></md-number-keyboard>
    <md-agree id="agree" v-show="false"
              v-model="agreeConf.checked"
              :disabled="agreeConf.disabled"
              :size="agreeConf.size"
    >
      <p>本人同意深信Aiy计算机协会收集用户的课程信息，并在将来的查询进行缓存记录与提醒使用。但过程并不收集学号和任何密码。</p>
    </md-agree>
    <WechatDialog msg="请先关注公众号" :banner="banner" ref="wechatd"></WechatDialog>
    <verificationCode ref="verify" @verify="verify"></verificationCode>
  </div>
</template>

<script>
import verificationCode from '@/components/verificationCode'
import {Agree, Button, Field, InputItem, NumberKeyboard, Toast, Dialog} from 'mand-mobile'
import {getPwd, getSid, setPasswordType, setPwd, setSid} from "../../util/auth";
import StuInput from "@/components/StuInput";
import {isWeiXin} from "@/util/utils";

export default {
  name: "StuInfo",
  mounted() {
    let sid = getSid()
    let pwd = getPwd()
    if (sid != null) {
      this.stuno = sid
      this.stupwd = pwd
    }
    // this.cap = new TencentCaptcha('2078900579', this.scoreQueryCaptcha, {needFeedBack: false});
   // this.cap = new TencentCaptcha(document.querySelector('#queryBtn'), '2078900579', this.scoreQueryCaptcha, {needFeedBack: false})
    Dialog.alert({
      title: '警告！',
      content: this.tip,
      cancelText: '取消',
      confirmText: '确定',
    })
  },
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Button.name]: Button,
    [NumberKeyboard.name]: NumberKeyboard,
    [Agree.name]: Agree,
    verificationCode,
    StuInput
  },
  data() {
    return {
      tip: '不要傻傻的去问老师为什么给那么低分数。成绩数据仅供参考，真实成绩请以教务系统为准，数据不作任何证明，可能存在偏差性，本网站不对数据真实性负责。',
      cap: {},
      banner: '',
      failedCount: 0,
      isKeyBoardShow: false,
      stuno: process.env.VUE_APP_SID,
      stupwd: process.env.VUE_APP_PWD,
      loading: false,
      errorCount: 0,
      passwordType: '教务系统', // 密码模式
      agreeConf: {
        checked: false,
        name: 'agree0',
        size: 'md',
        disabled: false,
        introduction: '选中状态',
      },
    }
  },
  methods: {
    verify(verifyinfo){
      this.login(verifyinfo)

    },
    stuinput(e) {

      console.log(e)
      setSid(e.stuno)
      setPwd(e.stupwd)
      this.stuno = e.stuno
      this.stupwd = e.stupwd
      this.passwordType = e.passwordType
      setPasswordType(e.passwordType)
      //打开验证码
      // 放出验证码
      this.$refs.verify.isShowVerifyContent = true



    },
    // 验证码
    scoreQueryCaptcha(verifyinfo) {
        this.loading = true

      this.$API.xtgl.submitZfcaptchaLogin(verifyinfo.rtk,verifyinfo.x,verifyinfo.c,'score',this.stuno, this.stupwd, this.passwordType, ).then(res => {
        this.$refs.verify.showLoadingToast()
        this.$refs.verify.isShowVerifyContent = false

        if (res.errno == 0) {
            this.$emit("login", res.data, {'sid': this.stuno, 'pwd': this.stupwd})

          } else {

            Toast.failed(res.msg)
          }
          this.loading = false
        }).catch(e => {
          console.log(e);
          this.loading = false;
        this.$refs.verify.isShowVerifyContent = false
        this.$refs.verify.showLoadingToast()
        this.$refs.verify.getData()

        Toast.failed('查询失败，要不问问客服？')

        })

    },
    forgetPwd() {
      Dialog.alert({content: '经查询，你需与你的学院的教务办老师联系。并告知其帮你重置教务系统密码！', title: '重置密码'})
    },
    login(verifyinfo) {
      console.log('logining')
      this.loading = true
      if (isWeiXin()){
        this.$API.user.hasUser().then(res => {
          this.loading = false;
          if (res.errno == 474 ) {
            //  弹出框
            this.banner = '/wechat/fuwuhao8cm.png'
            this.$refs.wechatd.openIt()
          }
        })
      }else{
        this.scoreQueryCaptcha(verifyinfo)
      }

    },
    onNumberEnter(val) {
      if (this.stuno.length >= 10) {
        this.isKeyBoardShow = false
        return
      }
      this.stuno += val
    },
    onNumberDelete() {
      if (this.stuno === '') {
        return
      }
      this.stuno = this.stuno.substr(0, this.stuno.length - 1)
    },

  }
}
</script>

<style lang="stylus" scoped>
.btn {
  margin 20px 0
}

#agree {
  margin-top 20px
}

p {
  font-size 30px
}

.content
  .md-field
    padding 0

.input-operator
  font-size 28px
  color rgba(79, 42, 240, 1)

</style>
