<template>
  <div>
    <md-field>
      <md-input-item
          class="mb-2"
          ref="stuno"
          preview-type="text"
          title="学号"
          placeholder="输入10位数字的学号"
          is-title-latent
          is-virtual-keyboard
          maxlength="10"
          is-highlight
          hide-dot
          virtual-keyboard-vm="stunokeyboard"
          v-model="stuno"
      ></md-input-item>
      <md-input-item
          class="mb-2"
          ref="stupwd"
          type="password"
          :title="`${passwordType}密码`"
          is-title-latent
          :placeholder="`${passwordType}密码`"
          is-highlight
          v-model="stupwd"
      >
        <div class="input-operator" slot="right" @click="forgetPwd">
          忘记密码
        </div>
      </md-input-item>
      <md-input-item
          class="mb-2"
          disabled
          :title="`密码模式`"
          is-highlight
      >
        <div class="input-operator" slot="right">
          <md-check-box
              name="教务系统"
              v-model="passwordType"
              label="教务系统"
              style="margin-right: 8px"
          />
          <!-- <md-check-box
              name="校统一身份"
              v-model="passwordType"
              label="校统一身份"
          /> -->
        </div>
      </md-input-item>
    </md-field>
    <md-button
        class="btn mb-2"
        type="primary"
        @click="stuinput"
        :loading="loading"
        :inactive="inactive"
    >查询
    </md-button>
    <md-button class="btn" type="" plain @click="$router.go(-1)"
    >返回
    </md-button>
    <md-number-keyboard
        ref="stunokeyboard"
        v-model="isKeyBoardShow"
        type="simple"
        @enter="onNumberEnter"
        @delete="onNumberDelete"
    ></md-number-keyboard>
  </div>
</template>

<script>
import {Dialog,NumberKeyboard} from "mand-mobile";
import {getPasswordType, getPwd, getSid, setPasswordType, setPwd, setSid} from "@/util/auth";

export default {
  name: "StuInput",
  components: {
    [NumberKeyboard.name]: NumberKeyboard,
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isKeyBoardShow: false,
      stuno: process.env.VUE_APP_SID,
      stupwd: process.env.VUE_APP_PWD,
      loading: false,
      passwordType: '教务系统',
      banner: '/wechat/fuwuhao8cm.png',
      agreeConf: {
        checked: false,
        name: 'agree0',
        size: 'md',
        disabled: false,
        introduction: '选中状态'
      }
    }
  },
  mounted() {
    let sid = getSid()
    let pwd = getPwd()
    let passwordType = getPasswordType()
    if (passwordType != null) {
      this.passwordType = passwordType
    }
    if (sid != null) {
      this.stuno = sid
      this.stupwd = pwd
    }
  },
  methods: {
    onNumberEnter(val) {
      if (this.stuno.length >= 10) {
        this.isKeyBoardShow = false
        return
      }
      this.stuno += val
    },
    onNumberDelete() {
      if (this.stuno === '') {
        return
      }
      this.stuno = this.stuno.substr(0, this.stuno.length - 1)
    },

    forgetPwd() {

      if (this.passwordType === '教务系统') {
        Dialog.alert({content: '经查询，你需与你的学院的教务办老师联系。并告知其帮你重置教务系统密码！', title: '重置密码'})
      } else {
        window.location = 'https://mp.weixin.qq.com/s/nkcAdgqCnIGj488M9VJAnQ'
      }
      // 之前判断密码系统的，后面没做信息门户的选择出了bug，所以注释了这部分，改成了下方的直接文字提示

    },

   /* forgetPwd() {
      Dialog.alert({content: '经查询，你需与你的学院的教务办老师联系。并告知其帮你重置教务系统密码！', title: '重置密码'})
    },*/
    stuinput() {
      setSid(this.stuno)
      setPwd(this.stupwd)
      setPasswordType(this.passwordType)
      if (!this.loading){
        this.$emit('stuinput', {stuno: this.stuno, passwordType: this.passwordType, stupwd: this.stupwd})
      }
    }
  }
}
</script>


<style lang="stylus" scoped>
.btn {
  margin 20px 0
}

.title {
  color #333;
  font-size 40px
  font-weight 600
  margin-bottom 15px
}

.input-operator
  font-size 28px
  color rgba(79, 42, 240, 1)

#agree {
  margin-top 20px
}

p {
  font-size 30px
}

.content
  .md-field
    padding 0
</style>

