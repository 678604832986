<template>
  <div class="evaluate">
    <div class="img">
      <div class="info">
        <img src="~@/assets/logo.png" alt="" />
      </div>
      <div class="name">张三</div>
      <div class="wechat">WeChat:weixin999</div>
    </div>
    <div class="content">
      评分
      <van-rate size="20" v-model="star" />
    </div>
    <van-field
      size="25"
      v-model="value"
      rows="10"
      autosize
      type="textarea"
      maxlength="50"
      placeholder="请输入留言"
      show-word-limit
      class="field"
    >
      <template #label> <p class="comment">留言</p> </template>
    </van-field>

    <!-- 捐赠 -->
    <div class="donate" @click.capture="showdonate">
      捐赠金额
      <div class="right">{{ money }}</div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          show-toolbar
          @cancel="onCancel"
          @confirm="onConfirm"
          :default-index="1"
        >
        </van-picker>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  name: 'evaluate',
  data () {
    return {
      star: 5,
      value: '',
      money: '10元',
      showPicker: false,
      columns: ['5元', '10元', '20元', '30元', '40元', '不捐赠']
    }
  },
  methods: {
    showdonate () {
      this.showPicker = true
    },
    // 取消
    onCancel () {
      this.showPicker = false
    },
    // 确认
    onConfirm (value, index) {
      // console.log(index)
      this.money = this.columns[index]
      this.showPicker = false
    }
  },
  mounted () {
    document.body.style.backgroundColor = '#f7f8fa'
  },
  beforeDestroy () {
    document.body.style.backgroundColor = '#fff'
  }
}
</script>

<style lang="stylus" scoped>
.evaluate{
  .img{
    position relative
    background url('~@/assets/bgc.jpg')
    background-size cover
    width 100%
    height 500px
    text-align center
    overflow hidden
    .info{
      width 150px
      height 150px
      background-color #fff
      border-radius 50%
      position absolute
      top 50%
      left 50%
      transform translate(-50%,-50%)
      img{
        width 100%
        height 100%
      }
    }
    .name{
      font-size 40px
      color #fff
      margin-top 350px
    }
    .wechat{
      white-space nowrap
      font-size 20px
      color #ccc
      margin-top 20px
    }
  }
  .content{
    background-color #fff
    box-sizing border-box
    height 100px
    font-size 25px
    display flex
    justify-content space-between
    align-items center
    .rates{
      height 100px
    }
  }

  .donate{
    font-size 25px
    padding 30px 35px
    background-color #fff
    border-top 1px #f7f8fa solid
    display flex
    justify-content space-between
  }

  /deep/ .van-picker__cancel,/deep/.van-picker__confirm{
    font-size 30px !important;
    padding 20px 30px
  }

  /deep/ .van-picker__toolbar{
    height 50px
  }

  .van-cell{
    padding 30px 35px
  }
  .content{
    margin 0
    padding 0 35px
    border-bottom 1px #f7f8fa solid
  }
  .comment{
    font-size 25px
  }
}
</style>
