<template>
  <div class="imgSwiper">
    <div class="swiperContainer">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiper"
          :key="index"
          @click="showBigImg(index)"
        >
          <img :src="item" class="img-item" alt="" />
        </div>
      </div>
    </div>
    <!-- 原图弹窗 -->
    <div class="img-popup" ref="box">
      <div class="shade" @click="cancel"></div>
      <div class="img-box"><img :src="swiper[index]" class="img-big" /></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name: 'IndexSwiper',
  data () {
    return {
      isViewerShow: false,
      viewerIndex: 0,
      swiper: [
        require('@/assets/swiper/5.jpg'),
        require('@/assets/swiper/4.jpg'),
        require('@/assets/swiper/3.jpg'),
        require('@/assets/swiper/2.jpg'),
        require('@/assets/swiper/1.jpg')
      ],
      index: 0 // 预览图片下标
    }
  },
  mounted () {
    this._initSwiper()
  },
  methods: {
    cancel () {
      this.$refs.box.style.display = 'none'
    },
    showBigImg (index) {
      this.$refs.box.style.display = 'block'
      this.index = index

    },
    _initSwiper () {
      var mySwiper = new Swiper('.swiperContainer', {
        autoplay: {
          delay: 2000
        },
        speed: 500,
        loop: true,
        loopedSlides: 3,
        slidesPerView: 'auto',
        effect: 'coverflow',
        pagination: '.swiper-pagination', //分页器
        centeredSlides: true, //active slide会居中
        coverflow: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。
          stretch: 100,
          depth: 150, //slide的位置深度。
          slideShadows: false //开启slide阴影。
        }
      })
    },
    showViewer () {
      let index = this.$refs.swiper.getIndex()
      console.log(index)
      this.viewerIndex = index
      this.isViewerShow = true
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../node_modules/swiper/css/swiper.min.css'
.imgSwiper
    // height 450px
    .swiper-wrapper
        width 100% !important
    .img-item
        width 100% !important
        border-radius: 15px !important

    .swiper-slide{

        width 100% !important
    }


    .img-popup
		    display: none
	.shade
		position: fixed
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: rgba(0,0,0,.6)
		z-index: 998
	.img-big
		position: fixed
		top: 50%
		left: 50%
		max-width: 100%
		z-index: 999
		transform: translate(-50%, -50%)
</style>
